<template>
  <Carousel autoplay arrows>
    <img class="hashkey-pc" src="../../assets/kiki-pc.jpg" alt="" @click="goKIKI" />
    <img class="hashkey-pc" src="../../assets/hashkey-pc.jpg" alt="" @click="goValidator" />
    <img class="hashkey-pc" src="../../assets/hackathon-pc.jpg" alt="" @click="goHackathon" />
  </Carousel>
</template>
<script setup>
import { Carousel } from 'ant-design-vue'

function goValidator() {
  window.open('https://btcyield.io/validators/hashkey.sat')
}
function goHackathon() {
  window.open('/events')
}

function goKIKI() {
  window.open('https://ibtc.kiki.finance/')
}
</script>
<style scoped>
.hashkey-pc {
  width: 1200px !important;
  height: 120px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  border-radius: 6px;
  cursor: pointer;
}
</style>
