<template>
  <nav class="header" :class="[navClass, { 'menu-active': menuShown }]">
    <div class="header-container">
      <div class="header-logo header-col">
        <a href="/" target="_top">
          <img :src="getSvg('logo')" />
        </a>
      </div>
      <div class="header-center header-col">
        <div class="top-menu">
          <div
            class="menu-item"
            v-for="menu in menuList"
            :key="menu.title"
            @mouseenter="onMenu(menu, true)"
            @mouseleave="onMenu('', true, 200)"
          >
            <div
              class="menu-title"
              :class="[{ active: activeMenu === menu.title }]"
              @click="onMenu(menu)"
            >
              {{ menu.title }}
              <img class="arrow-icon" :src="getSvg(menu.icon || 'chevron-down')" />
            </div>
            <transition name="fade-down">
              <div class="menu-card" v-if="activeMenu === menu.title && menu.items">
                <div class="menu-content">
                  <component
                    :is="navItem.to ? 'router-link' : 'a'"
                    class="menu-detail"
                    v-for="navItem in menu.items"
                    :key="navItem.title"
                    v-bind="getBind(navItem)"
                  >
                    <div class="menu-icon">
                      <img :src="getSvg(navItem.icon)" alt="icon" />
                    </div>
                    <div class="detail-content">
                      <div class="badge-container" v-if="navItem.badge">
                        <div class="badge badge-green">{{ navItem.badge }}</div>
                      </div>
                      <div class="detail-title">{{ navItem.title }}</div>
                      <div class="detail-context" v-if="navItem.context">{{ navItem.context }}</div>
                    </div>
                    <div class="arrow ml-auto" v-if="navItem.arrow">
                      <img src="@exsat/app/src/assets/img/arrow-right.svg" alt="" />
                    </div>
                  </component>
                </div>
                <div class="menu-links" v-if="menu.links">
                  <a
                    :href="i.href"
                    target="_blank"
                    class="menu-link"
                    v-for="i in menu.links"
                    :key="i.title"
                  >
                    <icon class="link-icon" :value="i.icon" />
                    {{ i.title }}
                    <icon value="chevron-right" />
                  </a>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="right-buttons header-col exsat-app-link">
        <a href="/app/bridge" class="btn btn-white" target="_blank">
          Bridge
          <icon value="external" />
        </a>
        <div class="menu-btn" @click="menuShown = !menuShown">
          <img src="@exsat/app/src/assets/img/close.svg" alt="" v-if="menuShown" />
          <img src="@exsat/app/src/assets/img/menu.svg" alt="" v-else />
        </div>
      </div>
    </div>
  </nav>

  <transition name="slide-left">
    <div class="mobile-nav" v-if="menuShown">
      <div class="nav-group" v-for="menu in menuList" :key="menu.title">
        <div class="nav-group-title">{{ menu.title }}</div>
        <div class="nav-group-list">
          <component
            class="nav-item"
            :is="navItem.to ? 'router-link' : 'a'"
            v-bind="getBind(navItem)"
            v-for="navItem in menu.items"
            :key="navItem.title"
          >
            <img class="icon" :src="getSvg(navItem.icon)" alt="" />
            <div>
              <div class="title">{{ navItem.title }}</div>
              <div class="desc">{{ navItem.context }}</div>
            </div>
            <div class="badge badge-green ml-auto" v-if="navItem.badge">{{ navItem.badge }}</div>
          </component>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { getSvg } from '@/utils.js'

const menuList = [
  // {
  //   title: 'Bridge',
  //   icon: 'external-white',
  //   href: '/app/bridge'
  // },
  {
    title: 'Ecosystem',
    items: [
      {
        title: 'BTC Metadata Sync Protocol',
        context: "Extends BTC's data consensus in real-time",
        href: '/app',
        icon: 'zap'
      },
      {
        title: 'Stake to Get Reward',
        context: 'Get XSAT reward with ease',
        href: import.meta.env.VITE_STAKEING_URL,
        icon: 'layers'
      },
      {
        title: 'Explorer',
        context: 'Block explorer and analytics platform',
        href: 'https://scan.exsat.network',
        icon: 'shield-check'
      }
    ]
  },
  {
    title: 'Build',
    items: [
      {
        title: 'Become a Synchronizer',
        context: 'Sync BTC Metadata, earn up to 50% rewards',
        href: 'https://docs.exsat.network/synchronizer-mining-pool',
        icon: 'zap'
      },
      {
        title: 'Become a Validator',
        context: 'Validate BTC Metadata, earn up to 90% rewards',
        href: 'https://docs.exsat.network/validator',
        icon: 'layers'
      },
      {
        title: 'Deploy a DApp',
        context: 'Secure, seamless Bitcoin integrationn',
        href: 'https://docs.exsat.network/developer-guides/developer-guides',
        icon: 'shield-check'
      },
      {
        title: 'Developer Incentive Program',
        context: 'Hackathon - Bitcoin Ecosystem Awakening',
        href: 'https://exsat.network/events/hackathon',
        icon: 'hackathon'
      }
    ],
    links: [
      {
        title: 'Docs',
        icon: 'docs',
        href: 'https://docs.exsat.network'
      },
      {
        title: 'Github',
        icon: 'github',
        href: 'https://github.com/exsat-network'
      },
      {
        title: 'Faucet',
        icon: 'faucet',
        href: 'https://faucet.exsat.network'
      }
    ]
  },
  {
    title: 'Community',
    items: [
      {
        title: 'Donate',
        context: 'Support the development of exSat network',
        href: '/app/donate',
        icon: 'zap'
      },
      {
        title: 'Medium',
        context: 'Stay updated by following our blog',
        href: 'https://medium.com/exsat-network',
        icon: 'layers'
      }
    ],
    links: [
      {
        title: 'Twitter',
        href: 'https://x.com/exsatnetwork',
        icon: 'twitter'
      },
      {
        title: 'Telegram',
        href: 'https://t.me/ExSatNetwork',
        icon: 'telegram'
      },
      {
        title: 'Discord',
        href: 'https://discord.gg/KrUJbemKwk',
        icon: 'discord'
      }
    ]
  }
]

const activeMenu = ref('')
const menuShown = ref(false)

const { navClass } = defineProps({
  navClass: [String, Array]
})

onMounted(() => {
  document.addEventListener('click', onWinClick, false)
})

onBeforeMount(() => {
  document.removeEventListener('click', onWinClick)
})

watch(
  () => menuShown.value,
  (val) => {
    document.body.style.overflow = val ? 'hidden' : null
  }
)

let t = null

function onMenu(val, force, interval = 0) {
  if (val && !val.items) {
    if (force) {
      return
    }
    window.open(val.href)
  }
  clearTimeout(t)
  t = setTimeout(() => {
    if (force) {
      activeMenu.value = val.title
      return
    }
    activeMenu.value = activeMenu.value === val.title ? '' : val.title
  }, interval)
}

function onWinClick(e) {
  if (!e.target.closest('.top-menu') && !e.target.closest('.menu-card')) {
    activeMenu.value = ''
  }
}

function getBind(item) {
  const bind = {}
  if (item.to) {
    bind.to = item.to
  }
  if (item.href) {
    bind.href = item.href
    bind.target = item.target || '_blank'
  }
  return bind
}
</script>

<style lang="scss" scoped>
.btn {
  text-decoration: none;
  padding: var(--spacing-md) var(--spacing-xl);
}
.header {
  z-index: 20;
  padding: 10px var(--spacing-xl);
  transition: 0.3s;

  @include sm {
    font-size: var(--font-md);
  }
}

.link-icon {
  width: 24px;
  height: 24px;
}

.header-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.header-col {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}

.right-buttons {
  flex: 0;
}

.header-center {
  flex: 0;
  padding-right: 1rem;
  @include sm {
    display: none;
  }
}

.menu-btn {
  img {
    width: 24px;
    height: 24px;
  }

  @include sm-up {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 10;
  padding: var(--spacing-2xl);
  padding-top: 75px;
  overflow-y: auto;
  font-weight: 600;

  .nav-group-title {
    color: var(--text-gray);
    font-size: var(--font-md);
    margin-bottom: 1em;
  }

  .nav-group {
    + .nav-group {
      margin-top: var(--spacing-3xl);
    }
  }

  .nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--text-white);
    padding: var(--spacing-md) 0;
    text-decoration: none;

    .icon {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    .desc {
      color: var(--text-gray);
      font-size: var(--font-sm);
      font-weight: 400;
    }
  }

  @include sm-up {
    display: none;
  }
}

:deep(.button-content) {
  padding: var(--spacing-lg) var(--spacing-xl);

  @include sm {
    padding: var(--spacing-sm) var(--spacing-lg);
  }
}

.logo {
  height: 60px;
  display: block;

  @include sm {
    height: 30px;
    min-width: 88px;
  }
}

.top-menu {
  position: relative;
  user-select: none;
  text-align: center;
  align-content: center;
  height: 56px;
  color: var(--text-white);
  display: inline-flex;
  flex-direction: row;

  .arrow-icon {
    width: 16px;
    height: 16px;
  }

  .menu-title {
    white-space: nowrap;
    padding: 12px 20px;
    height: 100%;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s;
    line-height: 32px;
    display: inline-flex;
    align-items: center;

    &:hover {
      color: var(--text-white);
    }

    .arrow-icon {
      display: block;
      transition: transform 0.3s;
      transition-timing-function: ease-in-out; // 两个状态动效不一样
    }
  }

  .menu-card {
    position: absolute;
    top: 100%;
    margin-top: 10px;
    overflow: hidden;
    background: var(--bg-color);
    border: solid 1px var(--border-gray);
    border-radius: var(--radius-2xl);
    padding: var(--spacing-md);
    text-align: left;
    white-space: nowrap;
    right: 0;

    .menu-detail {
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: var(--spacing-lg);
      min-width: 12em;
      border-radius: var(--radius-xl);

      &:hover {
        opacity: 1;
      }

      .menu-icon {
        border-radius: var(--radius-sm);
        overflow: hidden;
        background-color: #242424;
        transition: opacity 0.3s;
        position: relative;
        min-width: 40px;
        width: 40px;
        padding: 10px;

        img {
          display: block;
          width: 100%;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: radial-gradient(
            161.14% 105.56% at 50% 0%,
            rgba(0, 0, 0, 0) 32.17%,
            rgba(255, 153, 0, 0.6) 74.33%,
            #ffffff 100%
          );
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s;
        }
      }

      .arrow {
        transition: 0.3s;
        opacity: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover .menu-icon:after {
        opacity: 1;
      }

      &:hover,
      &:focus {
        background-color: var(--bg-secondary-color);

        .arrow {
          opacity: 1;
        }

        .badge-container {
          opacity: 1;

          .detail-title {
            opacity: 0;
          }
        }
      }
    }

    .detail-content {
      padding: 0 var(--spacing-xl);
      color: var(--text-gray);

      .detail-title {
        color: var(--text-white);
        transition: opacity 0.3s;
      }

      .detail-context {
        font-size: var(--font-sm);
      }
    }

    .badge-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center;
      font-size: var(--font-sm);
      padding-left: 64px;
      opacity: 0;
      transition: 0.3s;
    }
  }

  .active {
    color: var(--text-white);

    .arrow-icon {
      transform: rotate(180deg);
      transition-timing-function: cubic-bezier(0, 1.31, 0.79, 1.12); // 两个状态动效不一样
    }
  }
}

.net-dropdown {
  @include sm {
    display: none !important;
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: 0.3s;
}

.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.fade-down-enter-active,
.fade-down-leave-active {
  transition: 0.3s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
  transform: translateY(-10%);
}

.bg-dark {
  background-color: #000;
  z-index: 999;
}

.bg-transparent {
  background-color: transparent !important;
}

.menu-links {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-lg);
  padding: 10px;

  .menu-link {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 100%;
    padding: 10px 24px;
    border-radius: var(--radius-md);
    border: 1px solid var(--border-gray);
    text-decoration: none;
  }
}
.exsat-app-link {
  text-decoration: none;
  min-width: 96px;
}
</style>
